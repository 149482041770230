<template>
  <section>
    <v-row>
      <v-col>
        <v-row class="mx-sm-6" no-gutters>
          <v-row class="mx-sm-6">
            <v-col cols="12">
              <span class="text-h6 secondary--text">Registros de compras</span>
            </v-col>

            <v-col cols="12" sm="6">
              <v-menu
                v-model="show_date_picker_var_fechaDlDe"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
              >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :value="fecha_dl_de"
                  label="Fecha D.L. O D.E. *"
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  outlined
                  v-bind="attrs"
                  v-on="on"
                  clearable
                  @input="$v.fecha_dl_de.$touch"
                  :error-messages="fechaDlDeError"
                />
              </template>
              <v-date-picker
                v-model="fecha_dl_de"
                type="date"
                color="secondary"
                locale="es"
                @input="onDateChangeFechaDlDe"
                />
              </v-menu>
            </v-col>

            <v-col cols="6" sm="6">
              <v-text-field
                label="N° D.L. ó D.E. *"
                v-model="numero_dl_de"
                outlined
                @input="$v.numero_dl_de.$touch"
                :error-messages="numeroDlDeError"
              />
            </v-col>

            <v-col cols="6" sm="6">
              <v-menu
                v-model="show_date_picker_var_fechaResolucion"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
              >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :value="fecha_resolucion"
                  label="Fecha de resolución *"
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  outlined
                  v-bind="attrs"
                  v-on="on"
                  clearable
                  @input="$v.fecha_resolucion.$touch"
                  :error-messages="fechaResolucionError"
                />
              </template>
              <v-date-picker
                v-model="fecha_resolucion"
                type="date"
                color="secondary"
                locale="es"
                @input="onDateChangeFechaResolucion"
                />
              </v-menu>
            </v-col>

            <v-col cols="6" sm="6">
              <v-text-field
                label="Referencia de la resolución *"
                v-model="referencia_resolucion"
                outlined
                @input="$v.referencia_resolucion.$touch"
                :error-messages="referenciaResolucionError"
              />
            </v-col>

            <v-col cols="12">
              <v-text-field
                label="Descripción uso de producto Y/O servicio *"
                v-model="descripcion_uso_producto_servicio"
                outlined
                @input="$v.descripcion_uso_producto_servicio.$touch"
                :error-messages="descripcionUsoProductoServicioError"
              />
            </v-col>

            
            <v-col cols="6" sm="6">
              <v-autocomplete
                :items="tipoContratos"
                outlined
                item-value="id"
                item-text="nombre"
                label="Tipo documento contractual (Orden de compra u contrato) *"
                v-model="tipo_documento_contractual"
                @input="$v.tipo_documento_contractual.$touch"
                :error-messages="tipoDocumentoContractualError"
                >
              </v-autocomplete>
            </v-col>

            <v-col cols="6" sm="6">
              <v-text-field
                label="Número documento contractual *"
                v-model="numero_documento_contractual"
                outlined
                @input="$v.numero_documento_contractual.$touch"
                :error-messages="numeroDocumentoContractualError"
              />
            </v-col>
            
            <v-col cols="6" sm="6">
              <v-text-field
                label="Tipo documento fiscal o equivalente *"
                v-model="tipo_documento_fiscal"
                outlined
                @input="$v.tipo_documento_fiscal.$touch"
                :error-messages="tipoDocumentoFiscalError"
              />
            </v-col>

            <v-col cols="12" sm="6">
              <v-menu
                v-model="show_date_picker_var_fechaEmisionDocumentoFiscal"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
              >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :value="fecha_emision_documento_fiscal"
                  label="Fecha de emisión documento fiscal *"
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  outlined
                  v-bind="attrs"
                  v-on="on"
                  clearable
                  @input="$v.fecha_emision_documento_fiscal.$touch"
                  :error-messages="fechaEmisionDocumentoFiscalError"
                />
              </template>
              <v-date-picker
                v-model="fecha_emision_documento_fiscal"
                type="date"
                color="secondary"
                locale="es"
                @input="onDateChangeFechaEmisionDocumentoFiscal"
                />
              </v-menu>
            </v-col>

            <v-col cols="6" sm="6">
              <v-text-field
                label="Número documento fiscal o equivalente *"
                v-model="numero_documento_fiscal"
                outlined
                @input="$v.numero_documento_fiscal.$touch"
                :error-messages="numeroDocumentoFiscalError"
              />
            </v-col>

            <v-col cols="6" sm="6">
              <v-text-field
                label="NIT/ DUI proveedor *"
                v-model="nit_dui_proveedor"
                outlined
                @input="$v.nit_dui_proveedor.$touch"
                :error-messages="documentoProveedorError"
              />
            </v-col>

            <v-col cols="6" sm="6">
              <v-text-field
                label="Nombre del proveedor *"
                v-model="nombre_proveedor"
                outlined
                @input="$v.nombre_proveedor.$touch"
                :error-messages="nombreProveedorError"
              />
            </v-col>

            <v-col cols="6" sm="6">
              <v-text-field
                label="Nombre del producto y/o servicio *"
                v-model="nombre_producto_servicio"
                outlined
                @input="$v.nombre_producto_servicio.$touch"
                :error-messages="nombreProductoServicioError"
              />
            </v-col>

            <v-col cols="12" sm="6">
              <v-text-field
                label="Cantidad *"
                v-model="cantidad"
                outlined
                @input="$v.cantidad.$touch"
                :error-messages="cantidadErrors"
                clearable
                type="number"
              />
            </v-col>

            <v-col cols="12" sm="6">
              <money-text-field-component
                label="Precio unitario *"
                v-model="precio_unitario"
                ref="precio_unitario"
                @input="$v.precio_unitario.$touch"
                :error-messages="precioUnitarioError"
              />
            </v-col>

            <v-col cols="12" sm="6">
              <money-text-field-component
                label="Total compra *"
                v-model="total_compra"
                ref="total_compra"
                @input="$v.total_compra.$touch"
                :error-messages="totalCompraError"
              />
            </v-col>

            <v-col cols="12" sm="6">
              <money-text-field-component
                label="Retenciones *"
                v-model="retenciones"
                ref="retenciones"
                @input="$v.retenciones.$touch"
                :error-messages="retencionesError"
              />
            </v-col>

            <v-col cols="12">
              <v-textarea
                label="Observación u comentarios *"
                v-model="observaciones"
                outlined
                rows="4"
                auto-grow
                clearable
                @input="$v.observaciones.$touch"
                :error-messages="observacionError"
                maxlength="250"
              />
            </v-col>
          </v-row>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn
          class="button-extra text-capitalize mt-4 mt-sm-0 mx-8 mx-sm-4"
          min-width="30%"
          color="secondary"
          outlined
          @click="$emit('close')"
        >
          Cancelar
        </v-btn>
        <v-btn
          class="button-extra text-capitalize mt-4 mt-sm-0 mx-8 mx-sm-4"
          min-width="30%"
          color="secondary"
          @click="createRegsFondo"
        >
          Registrar
        </v-btn>
      </v-col>
    </v-row>
  </section>
</template>
<script>
import { convertToFormData } from "@/utils/data";
import MoneyTextFieldComponent from "@/components/MoneyTextFieldComponent.vue";
import { helpers, required } from "vuelidate/lib/validators";
import { mapState } from "vuex";
import DataTableComponent from "@/components/DataTableComponent.vue";

const txtArea = helpers.regex(
  "txtArea",
  /^[A-Za-zñÑáéíóúÁÉÍÓÚüÜ0-9 ()/.,\n:;-]*$/
);
export default {
  components: { DataTableComponent, MoneyTextFieldComponent },
  data: () => ({
    // formulario
    show_date_picker_var_fechaDlDe: false,
    show_date_picker_var_fechaResolucion: false,
    show_date_picker_var_fechaEmisionDocumentoFiscal: false,
    fecha_dl_de: null,
    numero_dl_de: null,
    fecha_resolucion: null,
    referencia_resolucion: null,
    descripcion_uso_producto_servicio: null,
    tipo_documento_contractual: null,
    numero_documento_contractual: null,
    tipo_documento_fiscal: null,
    fecha_emision_documento_fiscal: null,
    numero_documento_fiscal: null,
    nit_dui_proveedor: null,
    nombre_proveedor: null,
    nombre_producto_servicio: null,
    cantidad: null,
    precio_unitario: null,
    total_compra: null,
    retenciones: null,
    observaciones: null,
    tipoContratos: [],
  }),
  validations: {
    fecha_dl_de: {
      required,
    },
    numero_dl_de: {
      required,
    },
    fecha_resolucion: {
      required,
    },
    referencia_resolucion: {
      required,
    },
    descripcion_uso_producto_servicio: {
      required,
    },
    tipo_documento_contractual: {
      required,
    },
    numero_documento_contractual: {
      required,
    },
    tipo_documento_fiscal: {
      required,
    },
    fecha_emision_documento_fiscal: {
      required,
    },
    numero_documento_fiscal: {
      required,
    },
    nit_dui_proveedor: {
      required,
    },
    nombre_proveedor: {
      required,
    },
    nombre_producto_servicio: {
      required,
    },
    cantidad: {
      required,
    },
    precio_unitario: {
      required,
    },
    total_compra: {
      required,
    },
    retenciones: {
      required,
    },
    observaciones: {
      required,
      txtArea,
    },
  },
  computed: {
    fechaDlDeError() {
      const errors = [];
      if (!this.$v.fecha_dl_de.$dirty) return errors;
      !this.$v.fecha_dl_de.required &&
        errors.push("El mes a reportar es requerido");
      return errors;
    },
    numeroDlDeError() {
      const errors = [];
      if (!this.$v.numero_dl_de.$dirty) return errors;
      !this.$v.numero_dl_de.required &&
        errors.push("El número de documento es requerido");
      return errors;
    },
    fechaResolucionError() {
      const errors = [];
      if (!this.$v.fecha_resolucion.$dirty) return errors;
      !this.$v.fecha_resolucion.required &&
        errors.push("La fecha de resolución es requerido");
      return errors;
    },
    referenciaResolucionError() {
      const errors = [];
      if (!this.$v.referencia_resolucion.$dirty) return errors;
      !this.$v.referencia_resolucion.required &&
        errors.push("La referencia de resolución es requerido");
      return errors;
    },
    descripcionUsoProductoServicioError() {
      const errors = [];
      if (!this.$v.descripcion_uso_producto_servicio.$dirty) return errors;
      !this.$v.descripcion_uso_producto_servicio.required &&
        errors.push("La descripción de uso de producto y/o servicio es requerido");
      return errors;
    },
    tipoDocumentoContractualError() {
      const errors = [];
      if (!this.$v.tipo_documento_contractual.$dirty) return errors;
      !this.$v.tipo_documento_contractual.required &&
        errors.push("El tipo de documento contractual es requerido");
      return errors;
    },
    numeroDocumentoContractualError() {
      const errors = [];
      if (!this.$v.numero_documento_contractual.$dirty) return errors;
      !this.$v.numero_documento_contractual.required &&
        errors.push("El número de documento contractual es requerido");
      return errors;
    },
    tipoDocumentoFiscalError() {
      const errors = [];
      if (!this.$v.tipo_documento_fiscal.$dirty) return errors;
      !this.$v.tipo_documento_fiscal.required &&
        errors.push("El tipo de documento fiscal es requerido");
      return errors;
    },
    fechaEmisionDocumentoFiscalError() {
      const errors = [];
      if (!this.$v.fecha_emision_documento_fiscal.$dirty) return errors;
      !this.$v.fecha_emision_documento_fiscal.required &&
        errors.push("La fecha de emisión de documento fiscal es requerido");
      return errors;
    },
    numeroDocumentoFiscalError() {
      const errors = [];
      if (!this.$v.numero_documento_fiscal.$dirty) return errors;
      !this.$v.numero_documento_fiscal.required &&
        errors.push("El número de documento fiscal es requerido");
      return errors;
    },
    documentoProveedorError() {
      const errors = [];
      if (!this.$v.nit_dui_proveedor.$dirty) return errors;
      !this.$v.nit_dui_proveedor.required &&
        errors.push("El número de documento de proveedor es requerido");
      return errors;
    },
    nombreProveedorError() {
      const errors = [];
      if (!this.$v.nombre_proveedor.$dirty) return errors;
      !this.$v.nombre_proveedor.required &&
        errors.push("El nombre del proveedor es requerido");
      return errors;
    },
    nombreProductoServicioError() {
      const errors = [];
      if (!this.$v.nombre_producto_servicio.$dirty) return errors;
      !this.$v.nombre_producto_servicio.required &&
        errors.push("El nombre del producto y/o servicio es requerido");
      return errors;
    },
    cantidadErrors() {
      const errors = [];
      if (!this.$v.cantidad.$dirty) return errors;
      !this.$v.cantidad.required &&
        errors.push("La cantidad es requerido");
      return errors;
    },
    precioUnitarioError() {
      const errors = [];
      if (!this.$v.precio_unitario.$dirty) return errors;
      !this.$v.precio_unitario.required && errors.push("El precio unitario es requerido");
      return errors;
    },
    totalCompraError() {
      const errors = [];
      if (!this.$v.total_compra.$dirty) return errors;
      !this.$v.total_compra.required && errors.push("El total de compra es requerido");
      return errors;
    },
    retencionesError() {
      const errors = [];
      if (!this.$v.retenciones.$dirty) return errors;
      !this.$v.retenciones.required && errors.push("La retención es requerido");
      return errors;
    },
    observacionError() {
      const errors = [];
      if (!this.$v.observaciones.$dirty) return errors;
      !this.$v.observaciones.required &&
        errors.push("La descripción es requerido");
      !this.$v.observaciones.txtArea &&
        errors.push("El motivo posee caracteres especiales no permitidos");
      return errors;
    },
    minimumMonthToReport() {
      return this.minDateToReport;
    },
    ...mapState(["selectedUnidad", "userInfo"]),
  },
  props: {
    id_fondo: {
      type: Number,
      required: true,
    },
    id_registro_informe_fondo: {
      type: Number,
      required: true,
    },
  },
  methods: {
    onDateChangeFechaDlDe(date) {
      this.fecha_dl_de = date;
      this.show_date_picker_var_fechaDlDe = false;
    },
    onDateChangeFechaResolucion(date) {
      this.fecha_resolucion = date;
      this.show_date_picker_var_fechaResolucion = false;
    },
    onDateChangeFechaEmisionDocumentoFiscal(date) {
      this.fecha_emision_documento_fiscal = date;
      this.show_date_picker_var_fechaEmisionDocumentoFiscal = false;
    },
    async createRegsFondo() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        try {
          await this.services.CompraPorEmergencia.postAddRegFondo(
            this.id_registro_informe_fondo,
            convertToFormData({
              fecha_dl_de: this.fecha_dl_de,
              numero_dl_de: this.numero_dl_de,
              fecha_resolucion: this.fecha_resolucion,
              referencia_resolucion: this.referencia_resolucion,
              descripcion_uso_producto_servicio: this.descripcion_uso_producto_servicio,
              tipo_documento_contractual: this.tipo_documento_contractual,
              numero_documento_contractual: this.numero_documento_contractual,
              tipo_documento_fiscal: this.tipo_documento_fiscal,
              fecha_emision_documento_fiscal: this.fecha_emision_documento_fiscal,
              numero_documento_fiscal: this.numero_documento_fiscal,
              nit_dui_proveedor: this.nit_dui_proveedor,
              nombre_proveedor: this.nombre_proveedor,
              nombre_producto_servicio: this.nombre_producto_servicio,
              cantidad: this.cantidad,
              precio_unitario: this.precio_unitario,
              total_compra: this.total_compra,
              retenciones: this.retenciones,
              observaciones: this.observaciones,
            })
          );
          this.pushAppMessage({
            message: "Registro del fondo creado correctamente",
            show: true,
            type: "success",
          });
          this.$emit("actualizar");
        } catch (e) {
          console.log(e);
        }
      }
    },
    async getTiposContrato() {
      const { data, status } =
        await this.services.ContratoService.getTipoContrato();
      if (status == 200) {
        this.tipoContratos = data;
      }
    },
  },
  async mounted() {
    this.getTiposContrato();
  },
};
</script>
<style>
.fillWidth {
  width: 100%;
  align-items: center;
  margin: 0%;
  padding: 0%;
}
.requerido {
  background-color: greenyellow !important;
}
.opcional {
  background-color: orange !important;
}
</style>
