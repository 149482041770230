<template>
	<section>
		<v-row>
			<v-col cols="12">
				<span class="text-h6 secondary--text">Registrar un nuevo informe</span>
			</v-col>
			<v-col>
				<v-row>
					<v-col cols="12" ms="4">
						<v-text-field 
							v-model="form.nombre_informe"
							:error-messages="nombreInformeError"
							@change="$v.form.nombre_informe.$touch()" 
							@blur="$v.form.nombre_informe.$touch()"
							label="Nombre de informe*" 
							outlined
							/>
					</v-col>
				</v-row>
				
				<v-row>
					<v-col cols="12" ms="4">
						<v-text-field 
							v-model="form.nombre_doc"
							label="Nombre de adjunto *" 
							outlined />
					</v-col>
				</v-row>

				<v-row>
					<v-col cols="12" ms="4">
						<v-file-input 
							v-model="form.doc" 
							label="Subir adjunto *" 
							multiple
							outlined 
							accept="application/pdf"
							show-size 
							counter 
							clearable>
							<template v-slot:append-outer>
								<v-row justify="center">
									<v-dialog
									v-model="infoRequerimiento"
									persistent
									max-width="750"
									>
										<template v-slot:activator="{ on, attrs }">
											<v-btn
											class="ml-3"
											icon
											v-bind="attrs"
											v-on="on"
											>
												<v-icon color="secondary">
													mdi-help-circle
												</v-icon>
											</v-btn>
										</template>
										<v-card>
											<v-card-title class="text-h6 text-uppercase">
												El contenido del informe debe ser al menos el siguiente:
											</v-card-title>
											<v-card-text>
												<ul class="listR text-body-1">
													<li class="mt-2" v-for="(item) in requerimientos">
													{{ item }}
													</li>
												</ul>
												<v-spacer></v-spacer>
												<div class="d-flex justify-center align-center mt-4">
													<v-btn
													color="primary"
													@click="infoRequerimiento = false"
													>
													Cerrar
													</v-btn>
												</div>
											</v-card-text>
										</v-card>
									</v-dialog>
								</v-row>
							</template>
						</v-file-input>
					</v-col>
				</v-row>
			</v-col>
		</v-row>
		<!-- Botones de accion -->
		<v-row>
			<v-col>
				<v-btn 
					class="button-extra text-capitalize mt-4 mt-sm-0 mx-8 mx-sm-4" 
					min-width="30%" 
					color="secondary" 
					outlined
					@click="$emit('close')">
					Cancelar
				</v-btn>
				<v-btn 
					class="button-extra text-capitalize mt-4 mt-sm-0 mx-8 mx-sm-4" 
					min-width="30%" 
					color="secondary"
					@click="crearInforme"
					:disabled="!form.nombre_informe"
					>
					Registrar
				</v-btn>
			</v-col>
		</v-row>
	</section>
</template>
<script>
import { convertToFormData } from "@/utils/data";
import MoneyTextFieldComponent from "@/components/MoneyTextFieldComponent.vue";
import { helpers, required } from "vuelidate/lib/validators";
import { mapState } from "vuex";
import DataTableComponent from "@/components/DataTableComponent.vue";

const txtArea = helpers.regex(
	"txtArea",
	/^[A-Za-zñÑáéíóúÁÉÍÓÚüÜ0-9 ()/.,\n:;-]*$/
);
export default {
	components: { DataTableComponent, MoneyTextFieldComponent },
	data: () => ({
		infoRequerimiento: false,
		requerimientos: [
        'Resolución razonada de la máxima autoridad para proceder a las compras por emergencia.',
		'Requerimiento de compra (especificaciones técnicas).',
        'Sondeo de mercado.',
        'Informe técnico de cumplimiento de requisitos por cada contratación.',
        'Acuerdo o resolución de adjudicación.',
        'Formalización de la contratación (contrato u orden de compra).',
        'Descripción de los documentos de respaldos de la compra, adjuntar anexos (facturas u otros documentos que respalden el pago).',
		'Archivos de respaldo adjuntos.'
		],
		// formulario
		form: {
			nombre_informe: null,
			doc: null,
			nombre_doc: null,
		},
		headers_documentos: [
			{
				text: "Documento",
				align: "center",
				value: "documento",
			},
			{
				text: "Nombre de documento",
				align: "center",
				value: "nombre",
			},
			{
				text: "Acciones",
				align: "center",
				value: "acciones",
				sortable: false,
			},
		],
	}),
	validations: {
		form: {
			nombre_informe: {
				required,
			},
		},
},
	computed: {
		...mapState(["selectedUnidad", "userInfo"]),
		nombreInformeError() {
			const errors = [];
			if (!this.$v.form.nombre_informe) return errors;
			!this.$v.form.nombre_informe.required && 
				errors.push("El nombre del informe es requerido");
			return errors;
		},
	},
	props: {
		id_fondo: {
			type: Number,
			required: true,
		},
	},
	methods: {
		async crearInforme() {
			this.$v.$touch();
			if (!this.$v.$invalid) {


				await this.services.CompraPorEmergencia.postAddInformeFondo(
					this.id_fondo,
					convertToFormData({
						nombre_informe: this.form.nombre_informe,
						adjunto: this.form.doc,
						nombre_doc: this.form.nombre_doc,
					})
				);
				this.pushAppMessage({
					message: "Registro del fondo creado correctamente",
					show: true,
					type: "success",
				});
				this.$emit("actualizarInforme");
			}
		},
	},
	async mounted() {
	},
};
</script>
<style>
.fillWidth {
	width: 100%;
	align-items: center;
	margin: 0%;
	padding: 0%;
}

.requerido {
	background-color: greenyellow !important;
}

.opcional {
	background-color: orange !important;
}
ul.listR{
	list-style: decimal;
}
</style>